<template>
  <div class="position-relative">
    <div class="card">
      <div class="card-body">
        <EditFields
          v-if="loaded"
          :dto="dto"
          :fields="fields"
          :keyIndex="0"
          @updateDTO="onUpdateDTO"
        ></EditFields>
      </div>
    </div>

    <div class="card mt-8">
      <div class="card-body">
        <button class="btn btn-primary" @click="save(null)">Save</button>
        <button
          class="btn btn-success"
          style="margin-left: 4px"
          v-for="(button, i) in buttons"
          :key="'button_' + i"
          @click="buttonAction(button)"
        >
          {{ button.name }}
        </button>
      </div>
    </div>
    <!-- Оверлей с условием отображения -->
    <div class="overlay position-absolute" v-show="showOverlay">
      <div class="loader"></div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import EditFields from "@/components/EditFields";
import { toast } from "vue3-toastify";

export default defineComponent({
  name: "EditEntity",
  components: {
    EditFields,
  },
  props: {
    fields: { type: Array },
    saveUrl: { type: String },
    getUrl: { type: String },
    id: { type: String },
    buttons: { type: Array },
  },
  data: function () {
    return {
      dto: {},
      showOverlay: true,
      loaded: false,
      isDataSaved: true,
    };
  },
  watch: {
    isDataSaved: {
      handler(newValue) {
        this.$emit("updateIsDataSaved", newValue);
      },
    },
  },

  created: function () {
    if (this.id) {
      ApiService.get("api", this.getUrl + this.id)
        .then((response) => {
          this.dto = response.data;
          this.loaded = true;
        })
        .finally(() => {
          this.showOverlay = false;
        });
    } else {
      this.showOverlay = false;
      this.loaded = true;
    }
  },

  methods: {
    saveData() {
      // Метод для сохранения данных
      this.isDataSaved = true;
    },
    changeData() {
      // Метод, который вызывается при изменении данных
      this.isDataSaved = false;
    },

    onUpdateDTO(dto) {
      this.dto = dto;
      this.changeData();
    },

    buttonAction: async function (button) {
      if (button.type === "saveWithArgs") {
        this.save(button.args);
        return;
      }

      button.action(this.dto);
    },

    save: async function (args) {
      this.showOverlay = true;
      let success = false;
      let arg = "?";
      if (args) {
        arg += args;
      }
      await ApiService.post(this.saveUrl + arg, this.dto)
        .then((response) => {
          this.dto = response.data;
          toast.success("Saved successfully");
          success = true;
        })
        .catch((error) => {
          if (error.response) {
            toast.error(
              "Error " +
                error.response.data.code +
                "\n" +
                error.response.data.error
            );
          }
        })
        .finally(() => {
          this.showOverlay = false;
        });

      if (success) {
        this.saveData();
      }
    },
  },
});
</script>
<style>
.position-relative {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Полупрозрачный белый цвет */
  z-index: 10; /* Убедитесь, что оверлей находится поверх карточки */
  display: flex;
  justify-content: center;
  align-items: center; /* Центрирование содержимого оверлея, если оно есть */
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
